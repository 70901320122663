import useAbortableEffect from 'Hooks/useAbortableEffect';
import useCampaigns from '../useCampaigns';
import ReportCampaignsTable, { CampaignTableRow } from '../CampaignsTable/ReportCampaignsTable';
import InstagramReportPostsTable from 'Modules/Plans/Reports/Report/Posts/InstagramReportPostsTable/InstagramReportPostsTable';
import useUrlState from 'Hooks/useUrlState';
import { ExpandedState } from '@tanstack/react-table';
import { useMemo } from 'react';
import useNonNullContext from 'Hooks/useNonNullContext';
import { OptionsContext } from 'contexts/OptionsContext/OptionsContext';
import useReportData from '../../ReportData/useReportData';

type Props = {
    reportId: string | undefined;
};

type UrlState = Partial<{
    expanded: string;
}>;

const ReportInstagramCampaigns = ({ reportId }: Props) => {
    const [urlState, setUrlState] = useUrlState<UrlState>();
    const { currencies } = useNonNullContext(OptionsContext);
    const {
        campaignsData,
        projectsData,
        brandsData,
        campaignStatsData,
        fetchData,
        getIsBrandLoading,
        getIsProjectLoading,
        getIsCampaignStatsLoading,
    } = useCampaigns('instagram');
    const {
        teams: { data: teams, status: teamsStatus },
    } = useReportData();
    const areCampaignsInitialized = campaignsData.isInitialized || campaignsData.status === 'error';
    const areCampaignsLoading = campaignsData.status !== 'error' && campaignsData.status !== 'success';

    const expandedState = useMemo(() => {
        const expanded = urlState?.expanded;

        if (!expanded) {
            return {};
        }

        return expanded.split(',').reduce((acc, campaignId) => {
            acc[campaignId] = true;
            return acc;
        }, {} as Record<string, boolean>);
    }, [urlState]);

    const setExpandedState = (expanded: ExpandedState) => {
        const expandedKeys = Object.keys(expanded).filter((key) => expanded[key as keyof ExpandedState]);
        setUrlState({ expanded: expandedKeys.join(',') });
    };

    useAbortableEffect(
        (signal) => {
            if (areCampaignsInitialized || !reportId) {
                return;
            }

            fetchData(
                {
                    report_id: reportId,
                    platform: 'instagram',
                },
                { signal }
            ).catch(() => {});
        },
        [areCampaignsInitialized, reportId, fetchData]
    );

    const rows: CampaignTableRow[] =
        campaignsData.data?.results?.map((campaign) => {
            const project = projectsData[campaign.project_id]?.data || null;
            const brand = (project && brandsData[project.brand_id]?.data) || null;
            const team = teams ? teams.find((team) => team.id === project?.team_id) || null : null;
            const currency = currencies.find((c) => c.id === campaign.currency_id);
            return {
                ...campaign,
                stats: campaignStatsData[campaign.id]?.data ?? null,
                project: project,
                team: team,
                brand: brand,
                currency: currency,
            };
        }) ?? [];

    return (
        <ReportCampaignsTable
            data={rows}
            isLoading={areCampaignsLoading}
            expandedState={expandedState}
            onExpandedChange={setExpandedState}
            renderSubComponent={(row) => <InstagramReportPostsTable campaign={row.original} />}
            meta={{
                isLoading: areCampaignsLoading,
                getIsCampaignStatsLoading: (row) => getIsCampaignStatsLoading(row.id),
                getIsBrandLoading: (row) => getIsBrandLoading(row.id),
                getIsProjectLoading: (row) => getIsProjectLoading(row.id),
                getIsTeamLoading: () => teamsStatus === 'loading',
            }}
        />
    );
};

export default ReportInstagramCampaigns;
