import cn from 'classnames';
import Table from '../Table';
import {
    DragDropContext,
    DragDropContextProps,
    Draggable,
    Droppable,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import React, { useState } from 'react';
import { flexRender, RowData } from '@tanstack/react-table';
import { TableProps } from '@round/ui-kit';
import { LockedCell } from './LockedCell';

export const DraggableContext = React.createContext<{
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    isDragActive: boolean;
} | null>(null);

export type DragTableProps = {
    droppableId: string;
    onDragEnd: DragDropContextProps['onDragEnd'];
    isDragDisabled?: boolean;
    canDragWholeRow?: boolean;
};

const DragAndDropTable = <TRow extends RowData>({
    droppableId,
    onDragEnd,
    isDragDisabled,
    canDragWholeRow = false,
    ...props
}: TableProps<TRow> & DragTableProps) => {
    //These states are used to determine if a row is being dragged so we can apply dimension-locking styles to the cells inside
    const [isDragging, setIsDragging] = useState(false);
    const [dragId, setDragId] = useState<string | null>(null);

    return (
        <DragDropContext
            onDragEnd={(dropResult, responderProvided) => {
                setIsDragging(false);
                setDragId(null);
                onDragEnd(dropResult, responderProvided);
            }}
            onBeforeDragStart={(dragStart) => {
                setIsDragging(true);
                setDragId(dragStart.draggableId);
            }}
        >
            <Droppable droppableId={droppableId}>
                {(provided) => {
                    return (
                        <Table
                            {...props}
                            renderBody={({
                                getPinnedEdgeClassName,
                                getPinnedStyles,
                                renderCell,
                                spannedRowsData,
                                table,
                                getRowClassName,
                                onRowClick,
                                renderSubComponent,
                                rowSpanHelper,
                                noDataLabel,
                            }) => {
                                return (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {!table.getRowModel().rows.length && noDataLabel && (
                                            <tr>
                                                <td colSpan={table.getLeafHeaders().length}>{noDataLabel}</td>
                                            </tr>
                                        )}

                                        {table.getRowModel().rows.map((row) => (
                                            <Draggable
                                                key={row.id}
                                                draggableId={row.id.toString()}
                                                index={row.index}
                                                isDragDisabled={isDragDisabled}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        //Facilitates accessing drag data in column definition cell renderers
                                                        <DraggableContext.Provider
                                                            value={{
                                                                provided,
                                                                snapshot,
                                                                isDragActive: isDragging,
                                                            }}
                                                        >
                                                            <tr
                                                                onClick={() => onRowClick?.(row)}
                                                                className={getRowClassName?.(row)}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...(canDragWholeRow ? provided.dragHandleProps : {})}
                                                            >
                                                                {row.getVisibleCells().map((cell) =>
                                                                    renderCell?.({
                                                                        cell,
                                                                        getPinnedEdgeClassName,
                                                                        getPinnedStyles,
                                                                        spannedRowsData,
                                                                        rowSpanHelper,
                                                                    })
                                                                )}
                                                            </tr>
                                                            {row.getIsExpanded() && (
                                                                <tr>
                                                                    <td colSpan={row.getVisibleCells().length}>
                                                                        {renderSubComponent?.(row)}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </DraggableContext.Provider>
                                                    );
                                                }}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                );
                            }}
                            renderCell={(props) => {
                                const spanId = props.rowSpanHelper?.[props.cell.column.id]?.(props.cell.row);
                                const rowSpanRange = spanId
                                    ? props.spannedRowsData[props.cell.column.id]?.[spanId]
                                    : undefined;

                                const isFirstRowInSpan = rowSpanRange?.[0] === props.cell.row.index;
                                const rowSpan =
                                    rowSpanRange && rowSpanRange.length > 1 ? rowSpanRange?.length : undefined;

                                return (
                                    <LockedCell
                                        key={props.cell.id}
                                        style={props.getPinnedStyles(props.cell.column)}
                                        className={cn(
                                            props.getPinnedEdgeClassName(props.cell.column),
                                            // @ts-ignore
                                            props.cell.column.columnDef.meta?.className
                                        )}
                                        rowSpan={rowSpanRange && !isFirstRowInSpan ? 0 : rowSpan}
                                        isDragging={isDragging && dragId === props.cell.row.id.toString()}
                                    >
                                        {flexRender(props.cell.column.columnDef.cell, props.cell.getContext())}
                                    </LockedCell>
                                );
                            }}
                        />
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDropTable;
