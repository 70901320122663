import { creatorbase } from '@round/api';
import { CellContext } from '@tanstack/react-table';
import cn from 'classnames';
import styles from './ExpandOrDragCell.module.css';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as ChevronRightIcon } from 'assets/whitelabel/ChevronRight.svg';
import { ReactComponent as DragIcon } from 'assets/whitelabel/Drag.svg';
import useNonNullContext from 'Hooks/useNonNullContext';
import { DraggableContext } from 'ui-new/whitelabel/Table/DragAndDropTable/DragAndDropTable';

type Row = Pick<creatorbase.Campaign, 'id'>;
type Context<TRow extends Row> = CellContext<TRow, TRow['id']>;

const ExpandOrDragCell = <TRow extends Row>({ row }: Context<TRow>) => {
    const { provided, snapshot, isDragActive } = useNonNullContext(DraggableContext);

    return (
        <>
            <IconButton onClick={() => row.toggleExpanded()} className={styles.expandButton}>
                <ChevronRightIcon className={cn({ [styles.expandIconExpanded]: row.getIsExpanded() })} />
                <div
                    className={cn(styles.dragHandle, {
                        [styles.isDragging]: snapshot.isDragging,
                        [styles.isOtherDragging]: isDragActive && !snapshot.isDragging,
                    })}
                    {...provided.dragHandleProps}
                    onMouseDown={(e) => {
                        //return on right click
                        if (e.button === 2) {
                            return;
                        }
                        row.toggleExpanded(false);
                    }}
                >
                    <DragIcon className={styles.dragIcon} />
                </div>
            </IconButton>
        </>
    );
};

export default ExpandOrDragCell;
