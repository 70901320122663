import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';
import { creatorbase, influencer } from '@round/api';
import { PublicReportContext } from '../../../PublicReportContext';
import { isNumber } from 'utility/utility';
import omit from 'lodash/omit';
import { makeYoutubeVideosDataHook } from 'Modules/Youtube/hooks/dataHooks/useYoutubeVideosDataHook';
import { makeYoutubeChannelsDataHook } from 'Modules/Youtube/hooks/dataHooks/useYoutubeChannelsDataHook';
import uniq from 'lodash/uniq';

const useYoutubeVideos = makeYoutubeVideosDataHook(PublicReportContext, ([values]) => values.youtube.videos);
const useYoutubeChannels = makeYoutubeChannelsDataHook(PublicReportContext, ([values]) => values.youtube.channels);

export function usePublicReportYoutubePosts(campaignId: number) {
    const postsData = useNonNullContextSelector(PublicReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(PublicReportContext, ([, dispatch]) => dispatch);

    const { data: videosData, fetchData: fetchVideos } = useYoutubeVideos();
    const { data: channelsData, fetchData: fetchChannels } = useYoutubeChannels();

    const fetchData = useCallback(
        async (publicReportId: string, requestInit?: RequestInit) => {
            if (!campaignId) {
                return;
            }

            const publicIdRequestInit = influencer.addPublicReportIdToRequestInit(publicReportId, requestInit || {});

            try {
                dispatch({ type: 'loadPosts', payload: campaignId });

                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000, ordering: '-view_count' },
                    publicIdRequestInit
                );

                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const contentIds = response.data.results
                        .map((post) => post.youtube_details?.content_id)
                        .filter(isNumber);

                    const channelIds = uniq(
                        response.data.results.map((post) => post.youtube_details?.account_id).filter(isNumber)
                    );
                    const channelIdsToFetch = channelIds.filter((channelId) => !channelsData[channelId]?.data);

                    fetchVideos(contentIds, omit(publicIdRequestInit, ['signal'])).catch(() => {});
                    fetchChannels(channelIdsToFetch, omit(publicIdRequestInit, ['signal'])).catch(() => {});

                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }
                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [campaignId, dispatch, fetchVideos, fetchChannels, channelsData]
    );

    return {
        postsData,
        videosData,
        channelsData,
        fetchData,

        getAreStatsLoading: (contentId: number | undefined) =>
            Boolean(
                contentId && videosData[contentId]?.status !== 'success' && videosData[contentId]?.status !== 'error'
            ),
        getIsAccountDataLoading: (channelId: number | null | undefined) =>
            Boolean(
                channelId &&
                    channelsData[channelId]?.status !== 'success' &&
                    channelsData[channelId]?.status !== 'error'
            ),
    };
}
