import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';
import { Project, PublicProject } from './projects.types';

export type GetProjectsParams = Partial<
    PaginatedRequest & {
        search: string;
        users_assigned_to_campaigns: string;
        id: string;
        team_id: number;
        accessible_by_team_id: number;
        client_song_id: string;
    }
>;
export type GetProjectsResponse<T extends Project | PublicProject> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ApiResponse<{ detail: string }, 403>
    | NotFoundResponse;

export async function getProjects(
    params: GetProjectsParams,
    requestInit: PublicReportRequestInit
): Promise<GetProjectsResponse<PublicProject>>;
export async function getProjects(
    params: GetProjectsParams,
    requestInit?: RequestInit
): Promise<GetProjectsResponse<Project>>;
export async function getProjects(
    params: GetProjectsParams,
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<GetProjectsResponse<Project | PublicProject>> {
    const response = await call(`/api/creatorbase/projects/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens: isPublicReportRequestInit(requestInit),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get projects');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetProjectResponse = ApiResponse<Project, 200> | ApiResponse<{ detail: string }, 403> | NotFoundResponse;

export async function getProject(projectId: number, requestInit?: RequestInit): Promise<GetProjectResponse> {
    const response = await fetchWithToken(`/api/creatorbase/projects/${projectId}/`, requestInit);

    if (response.status === 403 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get project');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostProjectData = Pick<Project, 'name' | 'brand_id' | 'song_id'>;

type PostProjectResponse =
    | ApiResponse<Project, 201>
    | ApiResponse<string[], 400>
    | ApiResponse<ApiResponseError<PostProjectData>, 400>
    | ForbiddenResponse;

export async function postProject(data: PostProjectData): Promise<PostProjectResponse> {
    const response = await fetchWithToken('/api/creatorbase/projects/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create project');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchProjectData = Pick<Project, 'name' | 'song_id'>;
type PatchProjectResponse = ApiResponse<Project, 200> | ForbiddenResponse | NotFoundResponse;

export async function patchProject(projectId: number, data: Partial<PatchProjectData>): Promise<PatchProjectResponse> {
    const response = await fetchWithToken(`/api/creatorbase/projects/${projectId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update project');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
