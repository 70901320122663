import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../ReportContext';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';

export default function useReportData() {
    const reportState = useNonNullContextSelector(ReportContext, ([values]) => values.report.report);
    const teamsState = useNonNullContextSelector(ReportContext, ([values]) => values.teams);
    const assigneesState = useNonNullContextSelector(ReportContext, ([values]) => values.report.assignees);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const fetchTeams = useCallback(
        async (teamIds: number[], requestInit?: RequestInit) => {
            if (!teamIds.length) {
                dispatch({ type: 'teamsLoadSuccess', payload: [] });
                return;
            }

            dispatch({ type: 'loadTeams' });
            const response = await creatorbase.getTeams(
                { id: teamIds.toString(), page_size: teamIds.length },
                requestInit
            );
            if (response.status === 200) {
                dispatch({ type: 'teamsLoadSuccess', payload: response.data.results });
                return response;
            }

            dispatch({ type: 'teamsLoadingError', payload: response.data.detail });
            return response;
        },
        [dispatch]
    );

    const fetchAssignees = useCallback(
        async (assigneeIds: number[]) => {
            if (!assigneeIds.length) {
                dispatch({ type: 'assigneesLoadSuccess', payload: [] });
                return;
            }

            dispatch({ type: 'loadAssignees' });
            const response = await creatorbase.getUsers({ id: assigneeIds.toString(), page_size: assigneeIds.length });
            if (response.status === 200) {
                dispatch({ type: 'assigneesLoadSuccess', payload: response.data.results });
                return response;
            }

            dispatch({ type: 'assigneesLoadingError', payload: response.data.detail });
            return response;
        },
        [dispatch]
    );

    const fetchData = useCallback(
        async (id: string, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadReport' });
                const response = await creatorbase.getReport(id, requestInit);
                if (response.status === 200) {
                    dispatch({ type: 'reportInitialized', payload: response.data });
                    await Promise.allSettled([
                        fetchTeams(response.data.teams_assigned_to_campaigns),
                        fetchAssignees(response.data.users_assigned_to_campaigns),
                    ]);

                    return response;
                }

                dispatch({ type: 'errorLoadingReport', payload: response.data.detail });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'errorLoadingReport', payload: 'Could not get report' });
                throw e;
            }
        },
        [dispatch, fetchAssignees, fetchTeams]
    );

    const updateReport = useCallback(
        async (data: Partial<creatorbase.PatchReportData>) => {
            if (!reportState.data?.id) {
                return;
            }

            const response = await creatorbase.patchReport(reportState.data.id, data);
            if (response.status === 200) {
                const newTeamIds = response.data.teams_assigned_to_campaigns.filter(
                    (teamId) => !teamsState.data?.some((team) => team.id === teamId)
                );

                const newAccountTeamMemberIds = response.data.users_assigned_to_campaigns.filter(
                    (userId) => !assigneesState.data?.some((user) => user.id === userId)
                );

                await Promise.allSettled([fetchTeams(newTeamIds), fetchAssignees(newAccountTeamMemberIds)]);

                dispatch({ type: 'updateReport', payload: response.data });
            }

            return response;
        },
        [dispatch, fetchTeams, teamsState.data, reportState.data?.id, assigneesState.data, fetchAssignees]
    );

    const createPublicReport = useCallback(
        async (data: creatorbase.PostPublicReportData) => {
            if (!reportState.data?.id) {
                return;
            }

            const response = await creatorbase.postPublicReport(data);
            if (response.status === 201) {
                dispatch({ type: 'updateReport', payload: { public_report_id: response.data.id } });
            }

            return response;
        },
        [dispatch, reportState.data?.id]
    );

    const deletePublicReport = useCallback(
        async (publicReportId: string) => {
            if (!reportState.data?.id) {
                return;
            }

            const response = await creatorbase.deletePublicReport(publicReportId);

            if (response.status === 204) {
                dispatch({ type: 'updateReport', payload: { public_report_id: null } });
            }

            return response;
        },
        [dispatch, reportState.data?.id]
    );

    return {
        ...reportState,
        teams: teamsState,
        assignees: assigneesState,
        fetchData,
        updateReport,
        createPublicReport,
        deletePublicReport,
    };
}
