import { creatorbase } from '@round/api';
import { getTableMetaHelper, SkeletonizedValue } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import styles from './ReportsTable.module.css';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import CircleBadge from 'Modules/Plans/components/CircleBadge/CircleBadge';
import { getUserNameInitials } from 'Modules/Plans/User/user.helpers';
import Dropdown from 'ui-new/whitelabel/Dropdown/Dropdown';
import { ReactComponent as EditIcon } from 'assets/whitelabel/Edit.svg';
import VirtualizedTable, { Props as VirtualTableProps } from 'ui-new/whitelabel/Table/VirtualizedTable';
import TruncatedTextCell from 'SharedComponents/TableComponents/TruncatedTextCell/TruncatedTextCell';

export type ReportsTableRow = creatorbase.Report & {
    assignees: creatorbase.User[];
};

type Props = Pick<VirtualTableProps<ReportsTableRow>, 'data' | 'onEndReached'> & {
    isLoading?: boolean;
    hasError?: boolean;
    onEditReport: (report: ReportsTableRow) => void;
};

type TableCellContext<TKey extends keyof ReportsTableRow> = CellContext<ReportsTableRow, ReportsTableRow[TKey]>;

type Meta = Pick<Props, 'isLoading' | 'onEditReport'>;
const getTableMeta = getTableMetaHelper<Meta>();

const ReportsTable = ({ data, hasError, isLoading, onEditReport, onEndReached }: Props) => {
    const navigate = useNavigate();

    const columns: ColumnDef<ReportsTableRow, any>[] = [
        {
            header: 'Report name',
            accessorKey: 'name',
            cell: ({ getValue, table, row: { original } }: TableCellContext<'name'>) => {
                const { isLoading } = getTableMeta(table);

                return (
                    <div className={styles.titles}>
                        <p className={styles.title}>
                            <SkeletonizedValue width="10rem" isInitialized={!isLoading}>
                                <TruncatedTextCell>{getValue()}</TruncatedTextCell>
                            </SkeletonizedValue>
                        </p>
                        <p className={styles.subTitle}>
                            <SkeletonizedValue width="7rem" isInitialized={!isLoading}>
                                <TruncatedTextCell>{original.description || '-'}</TruncatedTextCell>
                            </SkeletonizedValue>
                        </p>
                    </div>
                );
            },
        },
        {
            header: 'Created',
            accessorKey: 'created_at',
            cell: ({ getValue, table }: TableCellContext<'created_at'>) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                return moment(getValue()).format('D MMMM YYYY');
            },
        },
        {
            header: 'Assignees',
            accessorKey: 'assignees',
            cell: ({ getValue, table }: TableCellContext<'assignees'>) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                const users = getValue();
                return (
                    <div className={styles.teamContainer}>
                        {users.map((user, index) => (
                            <CircleBadge key={index} tooltip={user.name}>
                                {getUserNameInitials(user.name)}
                            </CircleBadge>
                        ))}
                    </div>
                );
            },
        },
        {
            header: '',
            id: 'actions',
            meta: {
                className: styles.actions,
            },
            cell: ({ row: { original }, table }) => {
                const { isLoading, onEditReport } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Dropdown>
                            {({ setIsOpen }) => (
                                <>
                                    <Dropdown.Target />
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            icon={EditIcon}
                                            onClick={() => {
                                                onEditReport(original);
                                                setIsOpen(false);
                                            }}
                                        >
                                            Edit report
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </>
                            )}
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const meta: Meta = {
        isLoading,
        onEditReport,
    };

    const emptyRows = new Array(10).fill({}) as ReportsTableRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <VirtualizedTable
            containerClassName={styles.tableContainer}
            className={styles.table}
            data={rows}
            columns={columns}
            meta={meta}
            onRowClick={(row) => {
                if (isLoading) {
                    return;
                }

                navigate(`${row.original.id}/`);
            }}
            noDataLabel={
                <div aria-label="no reports" className={styles.noDataContainer}>
                    {hasError ? "Couldn't fetch reports" : 'No reports found'}
                </div>
            }
            virtualizerOptions={{
                count: rows.length,
                estimateSize: () => 50,
                getItemKey: (index) => rows[index]?.id || index,
                overscan: 5,
            }}
            onEndReached={onEndReached}
        />
    );
};

export default ReportsTable;
