import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReportContext } from '../../ReportContext';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';
import { useInstagramPostStats } from './useInstagramPostStats';
import { isNumber } from 'lodash';
import useInstagramUserImages from './useInstagramUserImages';

export function useInstagramReportCampaignPosts(campaignId: number) {
    const postsState = useNonNullContextSelector(ReportContext, ([values]) => values.posts[campaignId]);
    const dispatch = useNonNullContextSelector(ReportContext, ([, dispatch]) => dispatch);

    const { data: postStatsData, fetchData: fetchStats } = useInstagramPostStats();
    const { data: userImagesData, fetchData: fetchImages } = useInstagramUserImages();

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadPosts', payload: campaignId });
                const response = await creatorbase.getPosts(
                    { campaign_id: campaignId.toString(), page_size: 1000, ordering: '-view_count' },
                    requestInit
                );
                if (response.status === 200) {
                    dispatch({
                        type: 'postsSuccess',
                        payload: { campaignId: campaignId, posts: response.data.results },
                    });

                    const postIds =
                        response.data.results.reduce<{ postId: string; contentId: number }[]>((acc, post) => {
                            if (
                                post.platform === 'instagram' &&
                                post.instagram_details &&
                                isNumber(post.instagram_details?.content_id)
                            ) {
                                acc.push({ postId: post.id, contentId: post.instagram_details?.content_id });
                            }
                            return acc;
                        }, []) ?? [];

                    const userIds = response.data.results
                        .filter((p): p is creatorbase.InstagramPost => p.platform === 'instagram')
                        .map((p) => p.instagram_details?.account_id)
                        .filter(isNumber);

                    Promise.allSettled([fetchStats(postIds).catch(() => {}), fetchImages(userIds)]);
                    return response;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: response.data.detail },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'postsIdle', payload: campaignId });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingPosts',
                    payload: { campaignId: campaignId, message: 'Could not get posts' },
                });
                throw e;
            }
        },
        [dispatch, campaignId, fetchStats, fetchImages]
    );

    return {
        postsData: postsState ?? null,
        postStatsData,
        userImagesData,
        fetchData,
        getAreStatsLoading: (postId: string) => Boolean(postStatsData[postId]?.status === 'loading'),
        getIsAccountDataLoading: (accountId: number | null | undefined) =>
            Boolean(accountId && userImagesData[accountId]?.status === 'loading'),
    };
}
