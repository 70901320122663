import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import useNonNullContext from 'Hooks/useNonNullContext';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useState } from 'react';
import { useRefreshPlatformStats } from './PlatformStatsContext';
import { useCampaigns } from './CampaignsContext';
import { useCampaignStatsActions } from './CampaignStatsContext/CampaignsStatsContext';
import { showNotification } from 'helpers';
import { useTiktokVideosActions } from './PostStatsContexts/TiktokVideosContext/TiktokVideosContext';
import { useYoutubePostStatsActions } from './PostStatsContexts/YoutubePostStatsContext/YoutubePostStatsContext';
import { useInstagramPostStatsActions } from './PostStatsContexts/InstagramPostStatsContext/InstagramPostStatsContext';
import { getAccountId } from './PostStatsContexts/helpers';
import { useProjectActions } from './ProjectContext';
import { useTiktokAccountsDataActions } from './AccountDataContext/TiktokAccountsContext/TiktokAccountsContext';
import { useInstagramAccountsDataActions } from './AccountDataContext/InstagramAccountsContext/InstagramAccountsContext';
import { useYoutubeAccountsDataActions } from './AccountDataContext/YoutubeAccountsContext/YoutubeAccountsContext';
import { getPostContentId } from 'Modules/Plans/Posts/helpers';

type State = DataState<creatorbase.Post[]>;
const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

const PostsContext = createContext<[State, Dispatch<SetStateAction<State>>] | null>(null);

type Props = { children?: ReactNode | undefined };
export const PostsProvider = ({ children }: Props) => {
    const state = useState<State>(initialState);
    return <PostsContext.Provider value={state}>{children}</PostsContext.Provider>;
};

export type UsePostsReturn = ReturnType<typeof usePosts>;

export function usePosts() {
    const [state, setState] = useNonNullContext(PostsContext);
    const { refreshProjectStats } = useProjectActions();
    const refreshPlatformStats = useRefreshPlatformStats();
    const { refreshCampaignStats } = useCampaignStatsActions();
    const { refetchCampaign } = useCampaigns();

    const { removeVideos: removeTiktokVideos, fetchData: fetchTiktokPosts } = useTiktokVideosActions();
    const {
        removeStats: removeInstagramPostStats,
        fetchData: fetchInstagramPostStats,
    } = useInstagramPostStatsActions();
    const { removeStats: removeYoutubePostStats, fetchData: fetchYoutubePostStats } = useYoutubePostStatsActions();
    const { fetchData: fetchTiktokAccountData } = useTiktokAccountsDataActions();
    const { fetchData: fetchInstagramAccountData } = useInstagramAccountsDataActions();
    const { fetchData: fetchYoutubeAccountData } = useYoutubeAccountsDataActions();

    const getPostStatsHandlers = useCallback(
        (platform: 'instagram' | 'tiktok' | 'youtube') => {
            switch (platform) {
                case 'tiktok':
                    return {
                        removePostStats: removeTiktokVideos,
                        fetchPostStats: fetchTiktokPosts,
                    };
                case 'instagram':
                    return {
                        removePostStats: removeInstagramPostStats,
                        fetchPostStats: fetchInstagramPostStats,
                    };
                case 'youtube':
                    return {
                        removePostStats: removeYoutubePostStats,
                        fetchPostStats: fetchYoutubePostStats,
                    };
            }
        },
        [
            removeInstagramPostStats,
            removeTiktokVideos,
            removeYoutubePostStats,
            fetchInstagramPostStats,
            fetchTiktokPosts,
            fetchYoutubePostStats,
        ]
    );

    const getAccountDataHandlers = useCallback(
        (platform: 'instagram' | 'tiktok' | 'youtube') => {
            switch (platform) {
                case 'tiktok':
                    return {
                        fetchAccountData: fetchTiktokAccountData,
                    };
                case 'instagram':
                    return {
                        fetchAccountData: fetchInstagramAccountData,
                    };
                case 'youtube':
                    return {
                        fetchAccountData: fetchYoutubeAccountData,
                    };
            }
        },
        [fetchTiktokAccountData, fetchInstagramAccountData, fetchYoutubeAccountData]
    );

    const fetchData = useCallback(
        async (params: Omit<creatorbase.GetPostsParams, 'ordering'>, requestInit?: RequestInit) => {
            setState((prev) => ({ error: null, status: 'loading', data: prev.data }));

            try {
                const posts = await creatorbase.getAllPosts({ ...params, ordering: '-view_count' }, requestInit);
                setState((prev) => ({ data: (prev.data ?? []).concat(posts), error: null, status: 'success' }));
                return posts;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    setState((prev) => ({ data: prev.data, error: null, status: 'idle' }));
                    return;
                }

                setState((prev) => ({ data: prev.data, error: 'Could not load posts', status: 'error' }));
            }
        },
        [setState]
    );

    const createPost = useCallback(
        async (data: creatorbase.PostPostData) => {
            const response = await creatorbase.postPost(data);
            if (response.status === 201) {
                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).concat(response.data),
                }));

                const { fetchPostStats } = getPostStatsHandlers(response.data.platform);
                const { fetchAccountData } = getAccountDataHandlers(response.data.platform);

                const contentId = getPostContentId(response.data);
                const accountId = getAccountId(response.data);

                Promise.all([
                    refreshProjectStats(),
                    refreshPlatformStats(),
                    refreshCampaignStats(response.data.campaign_id),
                    refetchCampaign(response.data.campaign_id),
                    contentId
                        ? fetchPostStats([{ postId: response.data.id, contentId }]).catch(() => {})
                        : Promise.resolve(),
                    accountId ? fetchAccountData([accountId]).catch(() => {}) : Promise.resolve(),
                ]).catch(() => {
                    showNotification('Could not refresh data', 'error');
                });
            }

            return response;
        },
        [
            setState,
            getPostStatsHandlers,
            getAccountDataHandlers,
            refreshProjectStats,
            refreshPlatformStats,
            refreshCampaignStats,
            refetchCampaign,
        ]
    );

    const updatePost = useCallback(
        async (postId: string, data: Partial<creatorbase.PatchPostData>) => {
            const post = state.data?.find((p) => p.id === postId);
            const currentPostContentId = post ? getPostContentId(post) : undefined;
            const currentAccountId = post ? getAccountId(post) : undefined;

            const response = await creatorbase.patchPost(postId, data);
            if (response.status === 200) {
                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).map((p) => (p.id === response.data.id ? response.data : p)),
                }));

                const newContentId = getPostContentId(response.data);
                const isContentChanged = currentPostContentId !== newContentId;

                const newAccountId = getAccountId(response.data);
                const isAccountChanged = currentAccountId !== newAccountId;

                const { fetchPostStats } = getPostStatsHandlers(response.data.platform);
                const { fetchAccountData } = getAccountDataHandlers(response.data.platform);

                Promise.all([
                    refreshProjectStats(),
                    refreshPlatformStats(),
                    refreshCampaignStats(response.data.campaign_id),
                    refetchCampaign(response.data.campaign_id),
                    isContentChanged && typeof newContentId === 'number'
                        ? fetchPostStats([{ postId: response.data.id, contentId: newContentId }])
                        : Promise.resolve(),
                    isAccountChanged && typeof newAccountId === 'number'
                        ? fetchAccountData([newAccountId])
                        : Promise.resolve(),
                ]).catch(() => {
                    showNotification('Could not refresh data', 'error');
                });
            }

            return response;
        },
        [
            state.data,
            setState,
            getPostStatsHandlers,
            getAccountDataHandlers,
            refreshProjectStats,
            refreshPlatformStats,
            refreshCampaignStats,
            refetchCampaign,
        ]
    );

    const deletePost = useCallback(
        async (postId: string) => {
            const post = state.data?.find((p) => p.id === postId);
            if (!post) {
                return;
            }

            const response = await creatorbase.deletePost(postId);
            if (response.status === 204) {
                setState((prev) => ({
                    ...prev,
                    data: (prev.data ?? []).filter((p) => p.id !== postId),
                }));

                const { removePostStats } = getPostStatsHandlers(post.platform);

                Promise.all([
                    refreshProjectStats(),
                    refreshPlatformStats(),
                    refreshCampaignStats(post.campaign_id),
                    refetchCampaign(post.campaign_id),
                    removePostStats([postId]),
                ]).catch(() => {
                    showNotification('Could not refresh data', 'error');
                });
            }

            return response;
        },
        [
            state.data,
            setState,
            getPostStatsHandlers,
            refreshProjectStats,
            refreshPlatformStats,
            refreshCampaignStats,
            refetchCampaign,
        ]
    );

    const reset = useCallback(() => setState(initialState), [setState]);

    return {
        ...state,
        fetchData,
        createPost,
        updatePost,
        deletePost,
        reset,
    };
}

export function usePostsActions() {
    const postsContext = useContext(PostsContext);
    const [state, setState] = postsContext ?? [];

    const removeCampaignPosts = useCallback(
        (campaignId: number) => {
            if (!setState || !state) {
                console.warn('Posts context is not provided');
                return;
            }

            const campaignPostsIds = state.data?.filter((p) => p.campaign_id === campaignId).map((p) => p.id) ?? [];

            setState((prev) => ({
                ...prev,
                data: (prev.data ?? []).filter((p) => campaignPostsIds?.includes(p.id)),
            }));

            return campaignPostsIds;
        },
        [setState, state]
    );

    return {
        removeCampaignPosts,
    };
}
