import { youtube } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import omit from 'lodash/omit';
import { PostIdData } from 'Modules/Plans/Posts/types';

type Data = { [postId: string]: DataState<youtube.YoutubeVideo | null> | undefined };

export type State = {
    isInitialized: boolean;
    data: Data;
};

export type Actions =
    | ReducerActionWithPayload<'youtubePostStatsLoading', { postIds: string[] }>
    | ReducerActionWithPayload<'youtubePostStatsSuccess', { idData: PostIdData[]; stats: youtube.YoutubeVideo[] }>
    | ReducerActionWithPayload<'youtubePostStatsError', { postIds: string[]; error: string }>
    | ReducerActionWithPayload<'youtubePostStatsIdle', { postIds: string[] }>
    | ReducerAction<'youtubePostStatsInitialized'>
    | ReducerActionWithPayload<'removeYoutubePostStats', string[]>
    | ReducerAction<'resetYoutubePostStats'>;

export const initialState: State = {
    isInitialized: false,
    data: {},
};

export const reducer = createReducer<State, Actions>({
    youtubePostStatsLoading: (state, { payload: { postIds } }) => {
        const incomingStatsAsLoading = postIds.reduce((acc, postId) => {
            acc[postId] = { status: 'loading', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingStatsAsLoading } };
    },
    youtubePostStatsSuccess: (state, { payload: { idData, stats } }) => {
        const incomingStatsAsSuccess = idData.reduce((acc, { postId, contentId }) => {
            const linkedStat = stats.find((stat) => stat.id === contentId) || null;

            acc[postId] = {
                status: 'success',
                data: linkedStat,
                error: null,
            };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingStatsAsSuccess } };
    },
    youtubePostStatsError: (state, { payload: { postIds, error } }) => {
        const incomingStatsAsError = postIds.reduce((acc, postId) => {
            acc[postId] = { status: 'error', data: null, error };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingStatsAsError } };
    },
    youtubePostStatsIdle: (state, { payload: { postIds } }) => {
        const incomingStatsAsIdle = postIds.reduce((acc, postId) => {
            acc[postId] = { status: 'idle', data: null, error: null };
            return acc;
        }, {} as Data);

        return { ...state, data: { ...state.data, ...incomingStatsAsIdle } };
    },
    youtubePostStatsInitialized: (state) => ({ ...state, isInitialized: true }),
    removeYoutubePostStats: (state, { payload }) => ({
        ...state,
        data: omit(state.data, payload),
    }),
    resetYoutubePostStats: () => initialState,
});
