import { Props as BaseProps, BaseCampaign } from './CampaignsTable';
import DragAndDropTable, { DragTableProps } from 'ui-new/whitelabel/Table/DragAndDropTable/DragAndDropTable';
import styles from './CampaignsTable.module.css';
import { ScrollView } from '@round/ui-kit';
import cn from 'classnames';

type Props<TRow extends BaseCampaign> = BaseProps<TRow> & DragTableProps;

const DragAndDropCampaignsTable = <TRow extends BaseCampaign>({
    data,
    isLoading,
    hasError,
    className,
    ...props
}: Props<TRow>) => {
    const emptyRows = new Array(10).fill({}) as TRow[];
    const rows = isLoading ? data.concat(emptyRows) : data;

    return (
        <ScrollView>
            <DragAndDropTable
                data={rows}
                enableExpanding
                manualExpanding
                className={cn(styles.table, className, { [styles.empty]: !data.length && !isLoading })}
                getRowClassName={(row) => cn({ [styles.expandedRow]: row.getIsExpanded() })}
                getRowId={(original, index) => original.id?.toString() ?? index.toString()}
                noDataLabel={
                    isLoading ? undefined : (
                        <div className={styles.noDataContainer} aria-label="no campaigns">
                            {hasError ? 'Could not load campaigns' : 'No campaigns yet'}
                        </div>
                    )
                }
                {...props}
            />
        </ScrollView>
    );
};

export default DragAndDropCampaignsTable;
