import { creatorbase } from '@round/api';
import { DataState } from 'App.types';
import { uniq } from 'lodash';
import { useCallback, useState } from 'react';

type State = DataState<{
    reports: creatorbase.Report[];
    assignees: creatorbase.User[];
}>;

const initialState: State = {
    data: null,
    error: null,
    status: 'idle',
};

export default function useReports() {
    const [state, setState] = useState<State>(initialState);

    const fetchData = useCallback(
        async (
            params: Pick<creatorbase.GetReportsParams, 'search' | 'assignee_ids' | 'page' | 'page_size'>,
            requestInit?: RequestInit
        ) => {
            setState((prev) => ({ status: 'loading', error: null, data: prev.data }));
            try {
                const response = await creatorbase.getReports(params, requestInit);
                if (response.status !== 200) {
                    setState((prev) => ({ data: prev.data, error: response.data.detail, status: 'error' }));
                    return response;
                }

                const existingAssigneeIds = state.data?.assignees.map((a) => a.id) ?? [];
                const assigneeIds = uniq(response.data.results.map((r) => r.assignee_ids).flat()).filter(
                    (id) => !existingAssigneeIds.includes(id)
                );
                if (!assigneeIds.length) {
                    setState((prev) => ({
                        data: {
                            reports: (prev.data?.reports ?? []).concat(response.data.results),
                            assignees: prev.data?.assignees ?? [],
                        },
                        status: 'success',
                        error: null,
                    }));
                    return response;
                }

                const assigneesResponse = await creatorbase.getUsers(
                    {
                        id: assigneeIds.join(),
                        page_size: assigneeIds.length,
                    },
                    requestInit
                );
                const assignees = assigneesResponse.status === 200 ? assigneesResponse.data.results : [];
                setState((prev) => ({
                    data: {
                        reports: (prev.data?.reports ?? []).concat(response.data.results),
                        assignees: (prev.data?.assignees ?? []).concat(assignees),
                    },
                    status: 'success',
                    error: null,
                }));

                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState((prev) => ({ data: prev.data, error: 'Could not get reports', status: 'error' }));
                throw e;
            }
        },
        [state.data?.assignees]
    );

    const reset = useCallback(() => {
        setState(initialState);
    }, []);

    const createReport = useCallback(
        async (data: creatorbase.PostReportData) => {
            const response = await creatorbase.postReport(data);

            if (response.status === 201) {
                reset();
            }

            return response;
        },
        [reset]
    );

    const updateReport = useCallback(
        async (reportId: string, data: Partial<creatorbase.PatchReportData>) => {
            const response = await creatorbase.patchReport(reportId, data);

            if (response.status !== 200) {
                return response;
            }

            const existingAssigneeIds = state.data?.assignees.map((a) => a.id) ?? [];
            const assignees = response.data.assignee_ids.filter((id) => !existingAssigneeIds.includes(id));
            if (!assignees.length) {
                setState((prev) => ({
                    ...prev,
                    data: {
                        assignees: prev.data?.assignees ?? [],
                        reports:
                            prev.data?.reports.map((report) =>
                                report.id === response.data.id ? response.data : report
                            ) ?? [],
                    },
                }));

                return response;
            }

            const assigneesResponse = await creatorbase.getUsers({
                id: assignees.toString(),
                page_size: assignees.length,
            });

            setState((prev) => ({
                ...prev,
                data: {
                    assignees: (prev.data?.assignees ?? []).concat(
                        assigneesResponse.status === 200 ? assigneesResponse.data.results : []
                    ),
                    reports:
                        prev.data?.reports.map((report) => (report.id === response.data.id ? response.data : report)) ??
                        [],
                },
            }));

            return response;
        },
        [state.data?.assignees]
    );

    return {
        ...state,
        fetchData,
        createReport,
        updateReport,
        reset,
    };
}
