import useAbortableEffect from 'Hooks/useAbortableEffect';
import useSongsData from './useSongsData';
import SongsTable, { SongsTableRow } from './SongsTable/SongsTable';
import styles from './SongsList.module.css';
import { ScrollView } from '@round/ui-kit';
import { useMemo, useState } from 'react';
import Button from 'ui-new/whitelabel/Button/Button';
import { ReactComponent as PlusIcon } from 'assets/whitelabel/Plus.svg';
import CreateSongModal from '../Modals/CreateSongModal/CreateSongModal';
import SongDataModal from '../SongDataModal/SongDataModal';
import UpdateSongModal from '../Modals/UpdateSongModal/UpdateSongModal';
import useUrlState from 'Hooks/useUrlState';
import SearchInput from 'ui-new/whitelabel/SearchInput/SearchInput';
import { creatorbase } from '@round/api';
import DeleteSongModal from '../Modals/DeleteSongModal/DeleteSongModal';
import { StylesConfig } from 'react-select';
import Select from 'ui-new/whitelabel/Select/Select';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

type UrlState = creatorbase.GetSongsParams & {
    songModal: number;
    status?: string;
};

const SongsList = () => {
    const featureFlags = useFeatureFlags();
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const [lastFetchedPage, setLastFetchedPage] = useState<number | undefined>(undefined);
    const [hasNextPage, setHasNextPage] = useState(false);
    const { data, status: songsStatus, fetchData, createSong, updateSong, deleteSong, reset } = useSongsData();
    const areSongsInitialized = songsStatus === 'success' || songsStatus === 'error';

    const resetPage = () => {
        reset();
        setLastFetchedPage(undefined);
        setHasNextPage(false);
    };

    useAbortableEffect(
        (signal) => {
            if (!areSongsInitialized && typeof lastFetchedPage !== 'number') {
                fetchData(
                    {
                        page: 1,
                        page_size: 25,
                        search: urlState.search,
                        status: urlState.status,
                        ordering: '-created',
                    },
                    { signal }
                )
                    .then((response) => {
                        if (response?.status === 200) {
                            setLastFetchedPage(1);
                            setHasNextPage(!!response.data.next);
                        }
                    })
                    .catch(() => {});
            }
        },
        [areSongsInitialized, fetchData, lastFetchedPage, urlState.search, urlState.status]
    );

    const rows: SongsTableRow[] = useMemo(
        () =>
            data?.songs.map((song) => {
                const musicSong = data.musicSongs.find((m) => m.id === song.music_song_id);
                return {
                    ...song,
                    musicSong,
                    timeSeries: data.timeSeries?.[song.id] ?? null,
                };
            }) ?? [],
        [data?.musicSongs, data?.songs, data?.timeSeries]
    );

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const songIdForModal = urlState.songModal ? Number(urlState.songModal) : undefined;

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedSongRowToEdit, setSelectedSongRowToEdit] = useState<SongsTableRow | null>(null);

    const onClickEditSong = (song: SongsTableRow) => {
        setSelectedSongRowToEdit(song);
        setIsUpdateModalOpen(true);
    };

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [songRowToDelete, setSongRowToDelete] = useState<SongsTableRow | null>(null);

    const onClickDeleteSong = (song: SongsTableRow) => {
        setSongRowToDelete(song);
        setIsDeleteModalOpen(true);
    };

    const songStatusOptions = [
        { value: 'released', label: 'Released' },
        { value: 'unreleased', label: 'Unreleased' },
    ];

    const songStatusSelectStyles: StylesConfig = {
        control: (base) => ({
            ...base,
            height: '2.1875rem',
            width: '12rem',
        }),
    };

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Songs</h1>
                </header>

                <menu className={styles.menu}>
                    <div className={styles.filters}>
                        <SearchInput
                            className={styles.searchInput}
                            value={urlState.search}
                            onChange={(e) => {
                                setUrlState({ search: e.target.value });
                                resetPage();
                            }}
                        />
                        {featureFlags.isEnabled('status-filter') && (
                            <Select
                                options={songStatusOptions}
                                value={songStatusOptions.find((option) => option.value === urlState.status) ?? null}
                                onChange={(option) => {
                                    setUrlState({ status: option?.value });
                                    resetPage();
                                }}
                                placeholder="Song status..."
                                styles={songStatusSelectStyles}
                                isClearable
                                isSearchable={false}
                            />
                        )}
                    </div>
                    <Button
                        appearance="primary"
                        onClick={() => setIsCreateModalOpen(true)}
                        iconLeft={<PlusIcon className={styles.plusIcon} />}
                    >
                        Song
                    </Button>
                </menu>

                <ScrollView className={styles.tableContainer}>
                    <SongsTable
                        data={rows}
                        isLoading={!areSongsInitialized}
                        hasError={songsStatus === 'error'}
                        onEditSong={onClickEditSong}
                        onDeleteSong={onClickDeleteSong}
                        onRowClick={(row) => {
                            setUrlState({ songModal: row.original.id });
                        }}
                        onEndReached={() => {
                            if (songsStatus === 'success' && hasNextPage) {
                                fetchData({
                                    page: (lastFetchedPage ?? 0) + 1,
                                    page_size: 25,
                                    search: urlState.search,
                                    status: urlState.status,
                                    ordering: '-created',
                                })
                                    .then((response) => {
                                        if (response?.status === 200) {
                                            setLastFetchedPage((page) => (page ?? 0) + 1);
                                            setHasNextPage(!!response.data.next);
                                        }
                                    })
                                    .catch(() => {});
                            }
                        }}
                    />
                </ScrollView>
            </div>

            <CreateSongModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                createSong={createSong}
            />

            <UpdateSongModal
                isOpen={isUpdateModalOpen}
                onClose={() => {
                    setIsUpdateModalOpen(false);
                    setSelectedSongRowToEdit(null);
                }}
                updateSong={updateSong}
                song={selectedSongRowToEdit}
                musicSong={selectedSongRowToEdit?.musicSong ?? null}
            />

            <DeleteSongModal
                isOpen={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                    setSongRowToDelete(null);
                }}
                song={songRowToDelete}
                musicSong={songRowToDelete?.musicSong ?? null}
                deleteSong={deleteSong}
            />

            <SongDataModal
                isOpen={typeof songIdForModal === 'number'}
                onClose={() => {
                    setUrlState({ songModal: undefined });
                }}
                songId={songIdForModal}
            />
        </>
    );
};

export default SongsList;
